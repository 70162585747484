<template>
    <div class="how-we-work py-md-5 py-4">
        <div class="bg mainPadding py-5 position-relative d-flex flex-lg-row flex-column align-items-center">
            <div class="box my-lg-5 pe-4">
                <p>Your Vision, Our Process</p>
                <h4>How We Work</h4>
                <p>
                    Discover our client-focused approach in this step-by-step guide. It outlines how we ensure transparency, align with your vision, and deliver exceptional results throughout the
                    development process.
                </p>
            </div>

            <div class="slider position-relative my-lg-5 mb-5 mt-3">
                <swiper
                    class="swiper mySwiper swiper1 h-100"
                    :modules="modules"
                    :slides-per-view="calculateSlidesPerView()"
                    :space-between="calculateSpaceBetween()"
                    :navigation="{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }"
                    @swiper="onSwiper"
                    @slideChange="onSlideChange"
                    :autoplay="{ delay: 5000 }"
                >
                    <!-- <div class="swiper-wrapper h-100"> -->
                    <swiper-slide class="swiper-slide rounded-5 overflow-hidden h-100">
                        <div class="box primary-bg px-3 py-4 w-100 d-flex flex-column align-items-start text-white">
                            <h1 class="text-start">01</h1>
                            <h5 class="text-start">Initial Consultation</h5>
                            <p class="text-start lh-base fw-light">
                                Start with a clear vision. In our first meeting, we focus on understanding your project in-depth, ensuring we’re aligned with your goals right from the beginning.
                            </p>
                        </div>
                    </swiper-slide>

                    <swiper-slide class="swiper-slide rounded-5 overflow-hidden h-100">
                        <div class="box secondary-bg px-3 py-4 w-100 d-flex flex-column align-items-start text-white">
                            <h1 class="text-start">02</h1>
                            <h5 class="text-start">Proposal & Pricing</h5>
                            <p class="text-start lh-base fw-light">
                                Transparent and fair pricing is our promise. Our detailed proposal includes a clear cost breakdown, avoiding surprises and ensuring you have complete control over your
                                budget.
                            </p>
                        </div>
                    </swiper-slide>

                    <swiper-slide class="swiper-slide rounded-5 overflow-hidden h-100">
                        <div class="box primary-bg px-3 py-4 w-100 d-flex flex-column align-items-start text-white">
                            <h1 class="text-start">03</h1>
                            <h5 class="text-start">Project Kick-off</h5>
                            <p class="text-start lh-base fw-light">
                                Setting the stage for success. We establish a realistic timeline at the outset, outlining each phase of the project, so you know exactly what to expect and when.
                            </p>
                        </div>
                    </swiper-slide>

                    <swiper-slide class="swiper-slide rounded-5 overflow-hidden h-100">
                        <div class="box secondary-bg px-3 py-4 w-100 d-flex flex-column align-items-start text-white">
                            <h1 class="text-start">04</h1>
                            <h5 class="text-start">Agile Development</h5>
                            <p class="text-start lh-base fw-light">
                                Building in visible stages, our agile development process allows you to see your project develop step by step. Each iteration brings your vision to life, ensuring
                                development is always in line with your original goals and requirements.
                            </p>
                        </div>
                    </swiper-slide>

                    <swiper-slide class="swiper-slide rounded-5 overflow-hidden h-100">
                        <div class="box primary-bg px-3 py-4 w-100 d-flex flex-column align-items-start text-white">
                            <h1 class="text-start">05</h1>
                            <h5 class="text-start">Ongoing Testing</h5>
                            <p class="text-start lh-base fw-light">
                                Quality in every step. Continuous testing ensures that each part of the project meets our high standards, keeping technical issues at bay and the project flowing
                                smoothly.
                            </p>
                        </div>
                    </swiper-slide>

                    <swiper-slide class="swiper-slide rounded-5 overflow-hidden h-100">
                        <div class="box secondary-bg px-3 py-4 w-100 d-flex flex-column align-items-start text-white">
                            <h1 class="text-start">06</h1>
                            <h5 class="text-start">Client Feedback</h5>
                            <p class="text-start lh-base fw-light">
                                Your voice matters. Regular check-ins for your feedback ensure that we are always moving in the right direc.on, keeping you involved, and the project aligned with your
                                vision.
                            </p>
                        </div>
                    </swiper-slide>

                    <swiper-slide class="swiper-slide rounded-5 overflow-hidden h-100">
                        <div class="box primary-bg px-3 py-4 w-100 d-flex flex-column align-items-start text-white">
                            <h1 class="text-start">07</h1>
                            <h5 class="text-start">Final Review & Adjustments</h5>
                            <p class="text-start lh-base fw-light">
                                Fine-tuning for perfection. The final review phase is your opportunity to request any adjustments, ensuring the final product is exactly as you envisioned.
                            </p>
                        </div>
                    </swiper-slide>

                    <swiper-slide class="swiper-slide rounded-5 overflow-hidden h-100">
                        <div class="box secondary-bg px-3 py-4 w-100 d-flex flex-column align-items-start text-white">
                            <h1 class="text-start">08</h1>
                            <h5 class="text-start">Delivery & Deployment</h5>
                            <p class="text-start lh-base fw-light">
                                Seamless and stress-free launch. Our experienced team ensures a smooth rollout of your project, handling all technical complexities for a successful launch.
                            </p>
                        </div>
                    </swiper-slide>

                    <swiper-slide class="swiper-slide rounded-5 overflow-hidden h-100">
                        <div class="box primary-bg px-3 py-4 w-100 d-flex flex-column align-items-start text-white">
                            <h1 class="text-start">09</h1>
                            <h5 class="text-start">Post-Launch Support</h5>
                            <p class="text-start lh-base fw-light">
                                Continued support for peace of mind. Even after launch, we’re here to help with any post-launch needs, ensuring your project remains up-to-date and continues to perform
                                optimally.
                            </p>
                        </div>
                    </swiper-slide>
                    <!-- </div> -->
                </swiper>

                <div class="swiper-button-next start-0 ms-5 text-secondary border border-1 border-secondary rounded-circle"></div>
                <div class="swiper-button-prev me-5 text-secondary border border-1 border-secondary rounded-circle"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { Navigation, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
export default {
    name: "HowWeWork",
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            swiper: null,
        };
    },
    methods: {
        calculateSlidesPerView() {
            // Adjust the number of slides per view based on screen width
            if (window.innerWidth >= 576.98) {
                return 2; // Number of slides per view for large screens
            } else {
                return 1; // Number of slides per view for small screens
            }
        },
        calculateSpaceBetween() {
            // Adjust the number of slides per view based on screen width
            if (window.innerWidth >= 576.98) {
                return 30; // Number of slides per view for large screens
            } else {
                return 10; // Number of slides per view for small screens
            }
        },
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {};
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, A11y, Autoplay],
        };
    },
};
</script>

<style>
.how-we-work .bg.animate {
    transform: translateX(-100%);
    opacity: 0;
    animation: mainAnima 0.5s forwards;
}

.how-we-work .bg {
    background-color: #ddecf2;
    width: 75%;
    border-radius: 10px 102px;
}

.how-we-work .bg .box {
    width: 50%;
}

.how-we-work .slider {
    margin-right: -80%;
    width: 100%;
}

.how-we-work .slider .swiper-button-next,
.how-we-work .slider .swiper-button-prev {
    top: 115%;
    width: 30px;
    height: 30px;
}

.how-we-work .slider .swiper-button-next::after,
.how-we-work .slider .swiper-button-prev::after {
    font-size: 0.8rem;
}

.how-we-work .slider .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.how-we-work .slider .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media (max-width: 991.98px) {
    .how-we-work .bg {
        width: 100%;
    }

    .how-we-work .bg .box {
        width: 100%;
    }

    .how-we-work .slider {
        margin-right: 0%;
    }
}
</style>
