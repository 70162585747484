<template>
    <div>
        <div class="card border-0 h-100 overflow-hidden rounded-4">
            <img :src="`${this.BASE_URL}${blog.banner}`" :alt="`${blog.alt}`" class="w-100 blogImg" @error="handleImageError" />
            <div class="p-3">
                <h5 class="fw-normal lh-base">{{ blog.title }}</h5>
                <div class="lh-lg mb-0 description textEllipsis4" v-html="blog.description"></div>
            </div>
        </div>
    </div>
</template>

<script>
// import store from "../store";
import imageNotFound from "../assets/image-not-found.jpg";
export default {
    name: "BlogBox",
    props: {
        blog: Object,
    },
    methods: {
        handleImageError(event) {
            event.target.src = imageNotFound;
        },
    },
};
</script>

<style>
.card .description img {
    width: 100% !important;
}
.blogs .card:hover {
    background-color: var(--primary-color) !important;
    color: #ffffff;
}
</style>
