<template>
    <div class="secondCover w-100 mainPadding position-relative overflow-hidden py-5 bg-black d-flex align-items-center justify-content-center bg-black bg-opacity-50">
        <img src="../assets/mainLogoLowOpacity.png" alt="" class="position-absolute top-50 start-50 translate-middle z-2" />
        <div class="box text-white text-center mt-5">
            <h1>Services</h1>
            <p class="mb-0">Explore our diverse services at Festa BPS, where cudng-edge technology and client-focused strategies merge to create exceptional digital solutions.</p>
        </div>
    </div>

    <div class="service mainPadding py-md-5 py-4 bg-white">
        <div class="row gy-4 mx-0">
            <div class="col-lg-2 col-sm-6 pe-xl-5 px-sm-3 px-1">
                <div class="d-flex align-items-center justify-content-start w-100">
                    <h4 class="mb-0 fw-normal primary-text">
                        Our<br />
                        Services
                    </h4>
                </div>
            </div>
            <div class="col-lg-10">
                <div class="row gy-4 boxes">
                    <div class="col-lg-4 col-sm-6 px-sm-3 px-1">
                        <div class="card p-sm-4 p-3 border-0 h-100">
                            <img src="../assets/service1.png" alt="" class="mb-3" />
                            <h5 class="fw-normal">Mobile Application Development</h5>
                            <p class="text-secondary lh-lg">Festa BPS creates engaging mobile apps that bring your ideas to life, ensuring a captivating and effective user experience.</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 px-sm-3 px-1">
                        <div class="card p-sm-4 p-3 border-0 h-100">
                            <img src="../assets/service2.png" alt="" class="mb-3" />
                            <h5 class="fw-normal">Windows Application Development</h5>
                            <p class="text-secondary lh-lg">Specialising in Windows app development, Festa BPS delivers sleek, robust applications tailored for the Windows platform.</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 px-sm-3 px-1">
                        <div class="card p-sm-4 p-3 border-0 h-100">
                            <img src="../assets/service3.png" alt="" class="mb-3" />
                            <h5 class="fw-normal">Software Product Design</h5>
                            <p class="text-secondary lh-lg">We design software products with a focus on user experience and functionality, turning innovative ideas into market-ready solutions.</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 px-sm-3 px-1">
                        <div class="card p-sm-4 p-3 border-0 h-100">
                            <img src="../assets/service1.png" alt="" class="mb-3" />
                            <h5 class="fw-normal">E-commerce Development</h5>
                            <p class="text-secondary lh-lg">Festa BPS builds dynamic e-commerce platforms, designed to make online shopping a breeze and significantly boost your sales.</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 px-sm-3 px-1">
                        <div class="card p-sm-4 p-3 border-0 h-100">
                            <img src="../assets/service2.png" alt="" class="mb-3" />
                            <h5 class="fw-normal">Web Application Development</h5>
                            <p class="text-secondary lh-lg">
                                Festa BPS develops custom web applications, perfectly blending user-friendliness with innovative functionality to elevate your online presence.
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 px-sm-3 px-1">
                        <div class="card p-sm-4 p-3 border-0 h-100">
                            <img src="../assets/service3.png" alt="" class="mb-3" />
                            <h5 class="fw-normal">Design</h5>
                            <p class="text-secondary lh-lg">
                                Our design team at Festa BPS focuses on creating visually stunning and usercentric designs, enhancing user engagement and brand identity.
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 px-sm-3 px-1">
                        <div class="card p-sm-4 p-3 border-0 h-100">
                            <img src="../assets/service1.png" alt="" class="mb-3" />
                            <h5 class="fw-normal">Development</h5>
                            <p class="text-secondary lh-lg">
                                Festa BPS excels in developing high-quality, scalable solutions, ensuring each project is built to perfection with the latest technologies.
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 px-sm-3 px-1">
                        <div class="card p-sm-4 p-3 border-0 h-100">
                            <img src="../assets/service2.png" alt="" class="mb-3" />
                            <h5 class="fw-normal">Research</h5>
                            <p class="text-secondary lh-lg">At Festa BPS, we undertake thorough research to stay ahead of trends, ensuring our solutions are innovative and effective.</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 px-sm-3 px-1">
                        <div class="card p-sm-4 p-3 border-0 h-100">
                            <img src="../assets/service3.png" alt="" class="mb-3" />
                            <h5 class="fw-normal">Digital Marketing</h5>
                            <p class="text-secondary lh-lg">
                                Elevate your brand with Festa BPS’s digital marketing strategies, designed to increase visibility and drive engagement in the digital world.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <howWeWork />
    <contactUs />
</template>

<script>
import howWeWork from "../components/HowWeWork.vue";
import contactUs from "../components/ContactUs.vue";
export default {
    name: "ServicesPage",
    components: {
        howWeWork,
        contactUs,
    },
    mounted() {
        document.title = "Festa Services";
    },
};
</script>

<style></style>
