<template>
    <div class="festa">
        <Navbar />
        <RouterView />
        <Footer />
    </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
export default {
    name: "App",
    components: {
        Navbar,
        Footer,
    },
};
</script>

<style>
:root {
    --primary-color: #1957a2;
    --secondary-color: #25aae1;
}

.mainPadding {
    padding-left: 100px !important;
    padding-right: 100px !important;
}

.festa {
    overflow-x: hidden;
}

.primary-bg,
.primary-bg:hover {
    background-color: var(--primary-color) !important;
}

.secondary-bg,
.secondary-bg {
    background-color: var(--secondary-color);
}

.primary-text,
.primary-text:hover {
    color: var(--primary-color);
}

img {
    object-fit: cover;
}

.blogImg {
    aspect-ratio: 16/9;
}

.cursorPointer {
    cursor: pointer;
}

.blogImg2 {
    aspect-ratio: 1/0.4;
}

a {
    text-decoration: none;
    color: initial;
}

.small-text {
    font-size: 0.8rem;
}

.festa-preloader {
    z-index: 999;
}

.festa-preloader.hide {
    display: none !important;
}

.gridDisplay3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
}

.square {
    aspect-ratio: 1/1;
}

.boxShadowNone {
    box-shadow: none !important;
}

/* .festa-preloader svg path:nth-child(1) {
    fill: #01a1e100;
    stroke-width: .7px;
    animation: preloader 2s ease-in-out infinite;
    stroke: var(--primary-color);
    stroke-dasharray: 760;
    stroke-dashoffset: 760;
} */
.festa-preloader img {
    animation: preloader 2s ease-in-out infinite;
}

.preloaderWidth {
    width: 200px;
}

.errorImage {
    max-width: 400px !important;
}
.textEllipsis4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@keyframes preloader {
    30% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    70% {
        opacity: 0;
    }
}

.bg-main svg path {
    opacity: 0.4;
    fill: #01a1e100;
    stroke-width: 0.7px;
}

.bg-main svg path:nth-child(1) {
    animation: firstLine 4s ease-in-out infinite;
    stroke: var(--primary-color);
    stroke-dasharray: 1773;
    stroke-dashoffset: 1773;
}

.bg-main svg path:nth-child(2) {
    animation: firstLine 3s ease-in-out infinite;
    stroke: var(--primary-color);
    stroke-dasharray: 1862;
    stroke-dashoffset: 1862;
}

.bg-main svg path:nth-child(3) {
    animation: firstLine 3s ease-in-out infinite;
    stroke: var(--primary-color);
    stroke-dasharray: 1776;
    stroke-dashoffset: 1776;
}

.bg-main svg path:nth-child(4) {
    animation: firstLine 2s ease-in-out infinite;
    stroke: var(--primary-color);
    stroke-dasharray: 1529;
    stroke-dashoffset: 1529;
}

.bg-main svg path:nth-child(5) {
    animation: firstLine 4s ease-in-out infinite;
    stroke: var(--primary-color);
    stroke-dasharray: 705;
    stroke-dashoffset: 705;
}

@keyframes firstLine {
    50% {
        stroke-dashoffset: 0;
    }
}
.secondCover {
    background-image: linear-gradient(45deg, #83d9ee, #4bacd4);
    height: 50vh;
}
@media (max-width: 767.98px) {
    .mainPadding {
        padding-left: 12px !important;
        padding-right: 12px !important;
    }
}
</style>
