<template>
    <nav class="navbar navbar1 navbar-expand-lg bg-white mainPadding py-0 position-fixed w-100 top-0" :class="{ 'bg-transparent': navbarTransparent }" id="navbar">
        <div class="container-fluid px-0">
            <RouterLink @click="closeCollapse" class="navbar-brand" to="/">
                <img src="../assets/logo.png" alt="" />
            </RouterLink>
            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                @click="toggleCollapse"
            >
                <i class="fa-solid fa-bars"></i>
            </button>
            <div class="collapse navbar-collapse text-center py-lg-0 py-4" :class="{ show: isCollapseOpen }" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item mx-2">
                        <RouterLink class="nav-link home" @click="closeCollapse" aria-current="page" to="/" :class="{ active: $route.path === '/' }">Home</RouterLink>
                    </li>
                    <li class="nav-item mx-2">
                        <RouterLink class="nav-link" @click="closeCollapse" to="/about" :class="{ active: $route.path === '/about' }">About us</RouterLink>
                    </li>
                    <li class="nav-item">
                        <RouterLink class="nav-link" @click="closeCollapse" to="/services" :class="{ active: $route.path === '/services' }">Services</RouterLink>
                    </li>
                    <li class="nav-item">
                        <RouterLink class="nav-link" @click="closeCollapse" to="/blogs" :class="{ active: $route.path === '/blogs' }">Blogs</RouterLink>
                    </li>
                    <li class="nav-item mx-2">
                        <RouterLink class="nav-link" @click="closeCollapse" to="/contact" :class="{ active: $route.path === '/contact' }">Contact us</RouterLink>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "navbarComponent",
    data() {
        return {
            navbarTransparent: true,
            isCollapseOpen: false, // Add a data property to track collapse state
        };
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.navbarTransparent = window.scrollY > 0 ? false : true;
        },
        toggleCollapse() {
            this.isCollapseOpen = !this.isCollapseOpen; // Toggle the collapse state
        },
        closeCollapse() {
            this.isCollapseOpen = false; // Method to close the collapse
        },
    },
};
</script>

<style>
.navbar {
    z-index: 10;
    transition: background-color 0.5s ease;
}

.navbar .navbar-brand img {
    width: 170px;
}
@media (max-width: 991.98px) {
    .navbar .navbar-collapse {
        background-color: #fff;
        border-radius: 18px;
    }
}
@media (max-width: 575.98px) {
    .navbar .navbar-brand img {
        width: 120px;
    }
}
</style>
