<template>
    <div class="secondCover w-100 mainPadding position-relative overflow-hidden py-5 bg-black d-flex align-items-center justify-content-center bg-black bg-opacity-50">
        <img src="../assets/mainLogoLowOpacity.png" alt="" class="position-absolute top-50 start-50 translate-middle z-2" />
        <div class="box text-white text-center mt-5">
            <h1>Contact Us</h1>
            <p class="mb-0">Transform your ideas with us at Festa BPS. Reach out now and let's start making your business goals a successful reality together.</p>
        </div>
    </div>

    <div class="contact mainPadding position-relative pb-5">
        <!-- <div class="leftGra position-absolute w-100 h-100 top-0 start-0"></div>
        <div class="bottomGra position-absolute w-100 h-100 top-0 start-0"></div> -->
        <div class="row gy-5 mt-0">
            <div class="col-sm-6">
                <h4>Connect with Festa BPS</h4>
                <p>Reach out to us, and we'll ensure you receive a timely and helpful response from our team.</p>

                <div class="boxBottom d-flex align-items-center mx-2 mb-4">
                    <div class="circle rounded-circle primary-bg text-white d-flex align-items-center justify-content-center me-3">
                        <i class="fa-solid fa-envelope fa-xl"></i>
                    </div>
                    <div>
                        <h6 class="fw-normal mb-0">EMAIL</h6>
                        <p class="mb-0 text-secondary">info@festabps.com</p>
                    </div>
                </div>

                <div class="boxBottom d-flex align-items-center mx-2 mb-4">
                    <div class="circle rounded-circle primary-bg text-white d-flex align-items-center justify-content-center me-3">
                        <i class="fa-solid fa-mobile fa-xl"></i>
                    </div>
                    <div>
                        <h6 class="fw-normal mb-0">PHONE</h6>
                        <p class="mb-0 text-secondary">+91 949 6036 834</p>
                    </div>
                </div>

                <a class="boxBottom d-flex align-items-center mx-2 mb-4 text-decoration-none text-body" target="_blank" href="https://twitter.com/festabps">
                    <div class="circle rounded-circle primary-bg text-white d-flex align-items-center justify-content-center me-3">
                        <i class="fa-brands fa-twitter fa-xl"></i>
                    </div>
                    <div>
                        <h6 class="fw-normal mb-0">TWITTER</h6>
                        <p class="mb-0 text-secondary">Follow Us</p>
                    </div>
                </a>

                <a class="boxBottom d-flex align-items-center mx-2 mb-4 text-decoration-none text-body" target="_blank" href="https://www.facebook.com/festabps">
                    <div class="circle rounded-circle primary-bg text-white d-flex align-items-center justify-content-center me-3">
                        <i class="fa-brands fa-facebook-f fa-xl"></i>
                    </div>
                    <div>
                        <h6 class="fw-normal mb-0">FACEBOOK</h6>
                        <p class="mb-0 text-secondary">Follow Us</p>
                    </div>
                </a>

                <a class="boxBottom d-flex align-items-center mx-2 mb-4 text-decoration-none text-body" target="_blank" href="https://in.linkedin.com/company/festabps">
                    <div class="circle rounded-circle primary-bg text-white d-flex align-items-center justify-content-center me-3">
                        <i class="fa-brands fa-linkedin-in fa-xl"></i>
                    </div>
                    <div>
                        <h6 class="fw-normal mb-0">LINKEDIN</h6>
                        <p class="mb-0 text-secondary">Follow Us</p>
                    </div>
                </a>
            </div>
            <div class="col-sm-6 d-flex align-items-center justify-content-center">
                <form class="box bg-white p-4 rounded-2 w-100 shadow rounded-4 py-5 needs-validation" novalidate @submit.prevent="sendEnquiry">
                    <div class="mb-3">
                        <p class="mb-1">Name</p>
                        <input type="text" class="form-control rounded-pill py-3 px-4 border name" placeholder="" required />
                        <div class="invalid-feedback">Please provide a valid Name.</div>
                    </div>

                    <div class="mb-3">
                        <p class="mb-1">Phone</p>
                        <input type="tel" class="form-control rounded-pill py-3 px-4 border phone" minlength="6" maxlength="15" pattern="(\+?\d[\d\s]{5,14})?" placeholder="" required />
                        <div class="invalid-feedback">Please provide a valid Phone Number.</div>
                    </div>

                    <div class="mb-3">
                        <p class="mb-1">E-Mail</p>
                        <input type="email" class="form-control rounded-pill py-3 px-4 border email" placeholder="" required />
                        <div class="invalid-feedback">Please provide a valid Email.</div>
                    </div>

                    <div class="mb-3">
                        <p class="mb-1">Message</p>
                        <textarea class="form-control border py-3 px-4 message" rows="4" placeholder="" required></textarea>
                        <div class="invalid-feedback">Please provide a valid Message.</div>
                    </div>

                    <button type="submit" class="primary-bg btn rounded-pill py-3 w-100 text-white">Submit</button>
                </form>
            </div>
        </div>

        <!-- <div class="locations mt-5">
            <div class="gridDisplay3">
                <div class="box square position-relative overflow-hidden rounded-3">
                    <img src="../assets/kochi.png" alt="" class="w-100 h-100 z-n1 top-0 start-0 position-absolute" />
                    <div class="layer bg-black bg-opacity-25 w-100 h-100 text-white d-flex flex-column justify-content-end p-3">
                        <h5>Kochi</h5>
                        <p class="mb-0 fw-normal d-none">Lorem ipsum dolorees e mollitia,molestiaee quuntur voluptasrem e mollitia,mole.</p>
                    </div>
                </div>

                <div class="box square position-relative overflow-hidden rounded-3">
                    <img src="../assets/kochi.png" alt="" class="w-100 h-100 z-n1 top-0 start-0 position-absolute" />
                    <div class="layer bg-black bg-opacity-25 w-100 h-100 text-white d-flex flex-column justify-content-end p-3">
                        <h5>Kochi</h5>
                        <p class="mb-0 fw-normal d-none">Lorem ipsum dolorees e mollitia,molestiaee quuntur voluptasrem e mollitia,mole.</p>
                    </div>
                </div>

                <div class="box square position-relative overflow-hidden rounded-3">
                    <img src="../assets/kochi.png" alt="" class="w-100 h-100 z-n1 top-0 start-0 position-absolute" />
                    <div class="layer bg-black bg-opacity-25 w-100 h-100 text-white d-flex flex-column justify-content-end p-3">
                        <h5>Kochi</h5>
                        <p class="mb-0 fw-normal d-none">Lorem ipsum dolorees e mollitia,molestiaee quuntur voluptasrem e mollitia,mole.</p>
                    </div>
                </div>

                <div class="box square position-relative overflow-hidden rounded-3">
                    <img src="../assets/kochi.png" alt="" class="w-100 h-100 z-n1 top-0 start-0 position-absolute" />
                    <div class="layer bg-black bg-opacity-25 w-100 h-100 text-white d-flex flex-column justify-content-end p-3">
                        <h5>Kochi</h5>
                        <p class="mb-0 fw-normal d-none">Lorem ipsum dolorees e mollitia,molestiaee quuntur voluptasrem e mollitia,mole.</p>
                    </div>
                </div>

                <div class="box square position-relative overflow-hidden rounded-3">
                    <img src="../assets/kochi.png" alt="" class="w-100 h-100 z-n1 top-0 start-0 position-absolute" />
                    <div class="layer bg-black bg-opacity-25 w-100 h-100 text-white d-flex flex-column justify-content-end p-3">
                        <h5>Kochi</h5>
                        <p class="mb-0 fw-normal d-none">Lorem ipsum dolorees e mollitia,molestiaee quuntur voluptasrem e mollitia,mole.</p>
                    </div>
                </div>

                <div class="box square position-relative overflow-hidden rounded-3">
                    <img src="../assets/kochi.png" alt="" class="w-100 h-100 z-n1 top-0 start-0 position-absolute" />
                    <div class="layer bg-black bg-opacity-25 w-100 h-100 text-white d-flex flex-column justify-content-end p-3">
                        <h5>Kochi</h5>
                        <p class="mb-0 fw-normal d-none">Lorem ipsum dolorees e mollitia,molestiaee quuntur voluptasrem e mollitia,mole.</p>
                    </div>
                </div>
            </div>
        </div> -->
    </div>

    <contactUs />
</template>

<script>
import contactUs from "../components/ContactUs.vue";
export default {
    name: "ContactUsPage",
    components: {
        contactUs,
    },
    methods: {
        sendEnquiry() {
            let form = document.querySelector(".contact form.needs-validation");
            if (form.checkValidity()) {
                let name = document.querySelector(".contact .name").value;
                let email = document.querySelector(".contact .email").value;
                let phone = document.querySelector(".contact .phone").value;
                let message = document.querySelector(".contact .message").value;
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                const raw = JSON.stringify({
                    name: name,
                    email: email,
                    phone: phone,
                    message: message,
                });

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow",
                };

                fetch(`${this.BASE_URL}contactus`, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        console.log(result);
                        if (result.value == true) {
                            alert("Enquiry sent successfully");
                            form.reset();
                        }
                    })
                    .catch((error) => console.error(error));
            } else {
                // If not valid, prevent the default form submission
                event.preventDefault();
                event.stopPropagation();

                // Add Bootstrap's 'was-validated' class to show validation feedback
                form.classList.add("was-validated");
            }
        },
    },
    mounted() {
        document.title = "Festa Contact Us";
    },
};
</script>

<style>
.contact .circle {
    aspect-ratio: 1;
    height: 60px;
    width: 60px;
}
.contact .form-control {
    box-shadow: none;
    background-color: #ffffff;
}
.contact textarea {
    border-radius: 34px;
}
.locations .gridDisplay3 .box:hover p {
    display: block !important;
    opacity: 1;
}
</style>
