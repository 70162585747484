<template>
    <div class="row mx-0 gy-4 mt-0 ourMission">
        <div class="col-sm-7 d-flex flex-column justify-content-center ps-0 pe-3">
            <div class="box px-0">
                <h5 class="mb-sm-4 lh-base">To Innovate And Create Best Products Available In The Market Without Reinventing The Wheel.</h5>
                <p class="lh-lg mb-sm-4">
                    A team of youngsters striving hard to get a world of opportunities opened up for every seller out there. Every business is equally important and so are all our customers, no matter
                    how big or small their venture. We are on a mission to provide customer centric service and play our part in the success stories of each hardworking business team out there.
                </p>
            </div>
        </div>

        <div class="col-sm-5 d-flex align-items-center justify-content-center px-md-5">
            <img src="../assets/about-us-2.png" alt="" class="w-100 square rounded-4 ms-md-5" />
        </div>
    </div>
</template>

<script>
export default {
    name: "OurMission",
};
</script>

<style></style>
