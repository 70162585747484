import { createApp } from "vue";
import App from "./App.vue";
import { createRouter, createWebHistory } from "vue-router";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import Home from "./views/Home.vue";
import AboutUs from "./views/AboutUs.vue";
import Services from "./views/Services.vue";
import Blogs from "./views/Blogs.vue";
import ContactUs from "./views/ContactUs.vue";
import Careers from "./views/Careers.vue";
import OurMission from "./components/OurMission.vue";
import Ourvision from "./components/OurVision.vue";
import OurValue from "./components/OurValue.vue";
import Developer from "./components/Developer.vue";
import Designer from "./components/Designer.vue";
import Researcher from "./components/Researcher.vue";
import BlogSingle from "./views/BlogSingle.vue";

import { BASE_URL } from "./config";
import store from "./store";
import TeamSingle from "./views/TeamSingle.vue";

const routes = [
    { path: "/", component: Home },
    {
        path: "/about",
        component: AboutUs,
        children: [
            { path: "", component: OurMission },
            { path: "ourMission", component: OurMission, meta: { scrollToTop: false } },
            { path: "ourVision", component: Ourvision, meta: { scrollToTop: false } },
            { path: "ourValue", component: OurValue, meta: { scrollToTop: false } },
        ],
    },
    { path: "/services", component: Services },
    { path: "/blogs", component: Blogs },
    { path: "/contact", component: ContactUs },
    {
        path: "/careers",
        component: Careers,
        children: [
            { path: "", component: Developer },
            { path: "developer", component: Developer, meta: { scrollToTop: false } },
            { path: "designer", component: Designer, meta: { scrollToTop: false } },
            { path: "researcher", component: Researcher, meta: { scrollToTop: false } },
        ],
    },
    { path: "/blogSingle/:id", name: "blogSingle", component: BlogSingle },
    { path: "/teamSingle/:id", name: "teamSingle", component: TeamSingle },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
            return { selector: to.hash };
        } else if (to.meta.scrollToTop !== false) {
            // Scroll to top unless specified otherwise
            return { left: 0, top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || "Festa";
    next();
});

const app = createApp(App);
app.use(router);
app.use(store);
app.config.globalProperties.BASE_URL = BASE_URL;
app.mount("#app");
