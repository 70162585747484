<template>
    <div class="box d-flex align-items-center justify-content-between mt-3 border-bottom py-3">
        <div>
            <h6 class="mb-2">Researcher</h6>
            <p class="mb-0">Kochi, Kerala, Full-Time</p>
        </div>
        <button class="btn primary-bg text-white py-2 rounded-pill px-4">Apply Now</button>
    </div>
    <div class="box d-flex align-items-center justify-content-between border-bottom py-3">
        <div>
            <h6 class="mb-2">Researcher</h6>
            <p class="mb-0">Kochi, Kerala, Full-Time</p>
        </div>
        <button class="btn primary-bg text-white py-2 rounded-pill px-4">Apply Now</button>
    </div>
</template>

<script>
export default {
    name: "ResearcherComponent",
};
</script>

<style></style>
