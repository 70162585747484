import { createStore } from "vuex";
import axios from "axios";
import { BASE_URL } from "./config";

export default createStore({
    state: {
        blogDataArray: [],
        singleBlogDataArray: [],
        testimonialDataArray: [],
        teamDataArray: [],
        singleTeamDataArray: [],
    },
    mutations: {
        setBlogDataArray(state, data) {
            state.blogDataArray = data;
        },
        setSingleBlogDataArray(state, data) {
            state.singleBlogDataArray = data;
        },
        setTestimonialDataArray(state, data) {
            state.testimonialDataArray = data;
        },
        setTeamDataArray(state, data) {
            state.teamDataArray = data;
        },
        setSingleTeamDataArray(state, data) {
            state.singleTeamDataArray = data;
        },
    },
    actions: {
        async fetchBlogData({ commit }) {
            try {
                const response = await axios.get(`${BASE_URL}viewallblogs`);
                const data = await response.data;

                commit("setBlogDataArray", data);
            } catch (error) {
                console.error("Error fetching API data:", error);
            }
        },
        async fetchSingleBlogData({ commit }, blogId) {
            try {
                const response = await axios.get(`${BASE_URL}viewblog/${blogId}`);
                const data = await response.data;

                commit("setSingleBlogDataArray", data);
            } catch (error) {
                console.error("Error fetching API data:", error);
            }
        },
        async fetchTestimonialData({ commit }) {
            try {
                const response = await axios.post(`${BASE_URL}viewtestimonial`);
                const data = await response.data;

                commit("setTestimonialDataArray", data);
            } catch (error) {
                console.error("Error fetching API data:", error);
            }
        },
        async fetchTeamData({ commit }) {
            try {
                const response = await axios.get(`${BASE_URL}viewourteam`);
                const data = await response.data;

                commit("setTeamDataArray", data);
            } catch (error) {
                console.error("Error fetching API data:", error);
            }
        },
        async fetchSingleTeamData({ commit }, teamId) {
            try {
                const requestBody = {
                    slno: teamId,
                };
                const response = await axios.post(`${BASE_URL}viewourteamsingle`, requestBody);
                const data = await response.data;

                commit("setSingleTeamDataArray", data);
            } catch (error) {
                console.error("Error fetching API data:", error);
            }
        },
    },
});
