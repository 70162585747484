<template>
    <div class="secondCover w-100 mainPadding position-relative overflow-hidden py-5 bg-black d-flex align-items-center justify-content-center bg-black bg-opacity-50">
        <img src="../assets/mainLogoLowOpacity.png" alt="" class="position-absolute top-50 start-50 translate-middle z-2" />
        <div class="box text-white text-center mt-5">
            <h1>{{ singleTeamArray[0]?.name }}</h1>
            <!-- <p class="mb-0">Explore our Festa BPS blog for expert insights and practical tech tips. Stay updated and ahead in the digital world with our latest posts.</p> -->
        </div>
    </div>

    <div class="teamSingle mainPadding py-md-5 py-4">
        <img :src="`${this.BASE_URL}${singleTeamArray[0]?.image}`" alt="" class="float-end rounded-4 mb-3 ms-sm-3" @error="handleImageError" />
        <h4 class="mb-0">{{ singleTeamArray[0]?.name }}</h4>
        <p class="text-secondary small-text">{{ singleTeamArray[0]?.designation }}</p>

        <div v-html="singleTeamArray[0]?.description"></div>
    </div>

    <div class="specialists mainPadding py-md-5 py-4 mb-md-0 mb-5">
        <div class="row gy-4 mx-0">
            <div class="col-lg-2 col-sm-3 px-1">
                <div class="d-flex align-items-center w-100">
                    <h4 class="mb-0 fw-normal primary-text">
                        Other<br />
                        Specialists
                    </h4>
                </div>
            </div>
            <div class="col-lg-10 col-sm-9 px-sm-3 px-1 position-relative">
                <swiper
                    class="swiper mySwiper teamSwiper h-100"
                    :modules="modules"
                    :slides-per-view="calculateSlidesPerView()"
                    :space-between="calculateSpaceBetween()"
                    :navigation="{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }"
                    @swiper="onSwiper"
                    @slideChange="onSlideChange"
                    :autoplay="{ delay: 5000 }"
                >
                    <!-- <div class="swiper-wrapper h-100"> -->
                    <swiper-slide v-for="team in otherTeamArray" :key="team.slno" class="text-decoration-none box overflow-hidden rounded-3 position-relative" @click="goToTeamSinglePage(team.slno)">
                        <teamBox :team="team" />
                    </swiper-slide>
                    <!-- </div> -->
                </swiper>
                <div class="swiper-button-next start-0 ms-5 text-secondary border border-1 border-secondary rounded-circle"></div>
                <div class="swiper-button-prev me-5 text-secondary border border-1 border-secondary rounded-circle"></div>
            </div>
        </div>
    </div>
</template>

<script>
import imageNotFound from "../assets/image-not-found.jpg";
import store from "../store";
import { Navigation, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import teamBox from "../components/TeamBox.vue";

export default {
    name: "TeamSingle",
    components: {
        teamBox,
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            singleTeamArray: [],
            swiper: null,
            otherTeamArray: [],
        };
    },
    methods: {
        goToTeamSinglePage(teamId) {
            // Construct the route path manually
            const routePath = `/teamSingle/${teamId}`; // Adjust the route path as per your route configuration

            // Navigate programmatically
            this.$router.push(routePath);
        },
        handleImageError(event) {
            event.target.src = imageNotFound;
        },
        calculateSlidesPerView() {
            // Adjust the number of slides per view based on screen width
            if (window.innerWidth >= 576.98) {
                return 3; // Number of slides per view for large screens
            } else {
                return 1; // Number of slides per view for small screens
            }
        },
        calculateSpaceBetween() {
            // Adjust the number of slides per view based on screen width
            if (window.innerWidth >= 576.98) {
                return 20; // Number of slides per view for large screens
            } else {
                return 10; // Number of slides per view for small screens
            }
        },
    },
    watch: {
        "$route.params.id": function (newId) {
            // Fetch new data based on the newId
            store.dispatch("fetchSingleTeamData", newId).then(() => {
                this.singleTeamArray = store.state.singleTeamDataArray.message;
                this.otherTeamArray = store.state.singleTeamDataArray.otherMembers;
            });
        },
    },
    mounted() {
        document.title = "Festa Team Single";
        const teamId = this.$route.params.id; // Get productId from route params
        store.dispatch("fetchSingleTeamData", teamId).then(() => {
            this.singleTeamArray = store.state.singleTeamDataArray.message;
            this.otherTeamArray = store.state.singleTeamDataArray.otherMembers;
        });
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {};
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, A11y, Autoplay],
        };
    },
};
</script>

<style>
.teamSingle {
    min-height: 480px;
}
.teamSingle img {
    width: 30%;
}
.specialists .box {
    width: 140px;
    aspect-ratio: 1;
    transition: all 1s ease-in-out;
}

.specialists .box .layer {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.567) 100%);
}

.specialists .swiper-button-next,
.specialists .swiper-button-prev {
    top: 115%;
    width: 30px;
    height: 30px;
}

.specialists .swiper-button-next::after,
.specialists .swiper-button-prev::after {
    font-size: 0.8rem;
}
@media (max-width: 1399.98px) {
    .teamSingle {
        min-height: 380px;
    }
}
@media (max-width: 1199.98px) {
    .teamSingle {
        min-height: 350px;
    }
}
@media (max-width: 991.98px) {
    .teamSingle {
        min-height: 450px;
    }
    .teamSingle img {
        width: 50%;
    }
}
@media (max-width: 767.98px) {
    .teamSingle {
        min-height: 430px;
    }
}
@media (max-width: 575.98px) {
    .teamSingle img {
        width: 100%;
    }
    .teamSingle {
        min-height: auto;
    }
}
</style>
