<template>
    <div class="secondCover w-100 mainPadding position-relative overflow-hidden py-5 bg-black d-flex align-items-center justify-content-center bg-black bg-opacity-50">
        <img src="../assets/mainLogoLowOpacity.png" alt="" class="position-absolute top-50 start-50 translate-middle z-2" />
        <div class="box text-white text-center mt-5">
            <h1>{{ singleBlogArray[0]?.title }}</h1>
            <!-- <p class="mb-0">Explore our Festa BPS blog for expert insights and practical tech tips. Stay updated and ahead in the digital world with our latest posts.</p> -->
        </div>
    </div>

    <div class="blogSingle mainPadding py-md-5 py-4">
        <p class="text-secondary small-text">{{ formattedDate }}</p>
        <div class="" v-html="singleBlogArray[0]?.description"></div>
    </div>

    <contactUs />
</template>

<script>
import contactUs from "../components/ContactUs.vue";
import store from "../store";
export default {
    name: "BlogSinglePage",
    components: {
        contactUs,
    },
    data() {
        return {
            singleBlogArray: [],
        };
    },
    computed: {
        formattedDate() {
            // Get the blog date from singleBlogArray
            const blogDate = this.singleBlogArray[0]?.blogdate;

            // Parse the blog date string into a Date object
            const date = new Date(blogDate);

            // Format the date into "Month Day, Year" format
            const options = { year: "numeric", month: "long", day: "numeric" };
            return date.toLocaleDateString("en-US", options);
        },
    },
    mounted() {
        document.title = "Festa Blog Single";
        const blogId = this.$route.params.id; // Get productId from route params
        store.dispatch("fetchSingleBlogData", blogId).then(() => {
            this.singleBlogArray = store.state.singleBlogDataArray.message;
        });
    },
};
</script>

<style>
.blogSingle img {
    width: 50% !important;
}
</style>
