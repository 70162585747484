<template>
    <div id="carouselExampleInterval" class="carousel slide cover vh-100 position-relative py-5 z-2" data-bs-ride="carousel">
        <div class="carousel-inner h-100">
            <div class="carousel-item mainPadding h-100 active" data-bs-interval="2000">
                <div class="row h-100 mt-sm-0 mt-5 gy-sm-0 gy-4">
                    <div class="col-md-5 col-sm-6 order-sm-0 order-1">
                        <div class="box d-flex flex-column justify-content-sm-center justify-content-end h-100">
                            <p class="mb-2">Innovate, Engage, Grow</p>
                            <h1 class="primary-text">Revolutionize Your Mobile App Experience</h1>
                            <p>Elevate your business with custom, user-centric mobile apps, designed to captivate and retain a global audience.</p>
                            <div><button class="btn primary-bg text-white py-2 rounded-pill px-4">Contact Us</button></div>
                        </div>
                    </div>

                    <div class="col-1 d-md-flex d-none"></div>

                    <div class="col-sm-6 d-flex align-items-center order-sm-1 order-0 h-100">
                        <img src="../assets/cover1.jpg" alt="" class="mx-md-5 object-fit-contain" />
                    </div>
                </div>
            </div>
            <div class="carousel-item mainPadding h-100" data-bs-interval="2000">
                <div class="row h-100 mt-sm-0 mt-5 gy-sm-0 gy-4">
                    <div class="col-md-5 col-sm-6 order-sm-0 order-1">
                        <div class="box d-flex flex-column justify-content-sm-center justify-content-end h-100">
                            <p class="mb-2">Precision, Performance, Excellence</p>
                            <h1 class="primary-text">Redefining Windows App Development Standards</h1>
                            <p>Unleash superior Windows applications, meticulously engineered for business efficiency and unmatched user experiences.</p>
                            <div><button class="btn primary-bg text-white py-2 rounded-pill px-4">Contact Us</button></div>
                        </div>
                    </div>

                    <div class="col-1 d-md-flex d-none"></div>

                    <div class="col-sm-6 d-flex align-items-center order-sm-1 order-0 h-100">
                        <img src="../assets/cover2.jpg" alt="" class="mx-md-5 object-fit-contain" />
                    </div>
                </div>
            </div>
            <div class="carousel-item mainPadding h-100" data-bs-interval="2000">
                <div class="row h-100 mt-sm-0 mt-5">
                    <div class="col-md-5 col-sm-6 order-sm-0 order-1">
                        <div class="box d-flex flex-column justify-content-sm-center justify-content-end h-100">
                            <p class="mb-2">Creative, Intuitive, Impactful</p>
                            <h1 class="primary-text">Crafting Next-Gen Software Products</h1>
                            <p>Transform your ideas into reality with our innovative software designs, blending art and technology for market success.</p>
                            <div><button class="btn primary-bg text-white py-2 rounded-pill px-4">Contact Us</button></div>
                        </div>
                    </div>

                    <div class="col-1 d-md-flex d-none"></div>

                    <div class="col-sm-6 d-flex align-items-center order-sm-1 order-0">
                        <img src="../assets/cover.jpg" alt="" class="mx-md-5 object-fit-contain" />
                    </div>
                </div>
            </div>
            <div class="carousel-item mainPadding h-100" data-bs-interval="2000">
                <div class="row h-100 mt-sm-0 mt-5">
                    <div class="col-md-5 col-sm-6 order-sm-0 order-1">
                        <div class="box d-flex flex-column justify-content-sm-center justify-content-end h-100">
                            <p class="mb-2">Robust, Scalable, Profitable</p>
                            <h1 class="primary-text">Revamp Your Online Sales Platform</h1>
                            <p>Empower your brand with our state-of-the-art e-commerce solutions, driving sales through exceptional user journeys.</p>
                            <div><button class="btn primary-bg text-white py-2 rounded-pill px-4">Contact Us</button></div>
                        </div>
                    </div>

                    <div class="col-1 d-md-flex d-none"></div>

                    <div class="col-sm-6 d-flex align-items-center order-sm-1 order-0">
                        <img src="../assets/cover3.jpg" alt="" class="mx-md-5 object-fit-contain" />
                    </div>
                </div>
            </div>
            <div class="carousel-item mainPadding h-100" data-bs-interval="2000">
                <div class="row h-100 mt-sm-0 mt-5">
                    <div class="col-md-5 col-sm-6 order-sm-0 order-1">
                        <div class="box d-flex flex-column justify-content-sm-center justify-content-end h-100">
                            <p class="mb-2">Innovative, Reliable, Dynamic</p>
                            <h1 class="primary-text">Leading Web Application Development Frontier</h1>
                            <p>Experience the pinnacle of web applications, tailored for your business, ensuring seamless interaction and digital supremacy.</p>
                            <div><button class="btn primary-bg text-white py-2 rounded-pill px-4">Contact Us</button></div>
                        </div>
                    </div>

                    <div class="col-1 d-md-flex d-none"></div>

                    <div class="col-sm-6 d-flex align-items-center order-sm-1 order-0 h-100">
                        <img src="../assets/cover4.jpg" alt="" class="mx-md-5 object-fit-contain" />
                    </div>
                </div>
            </div>
            <div class="carousel-item mainPadding h-100" data-bs-interval="2000">
                <div class="row h-100 mt-sm-0 mt-5">
                    <div class="col-md-5 col-sm-6 order-sm-0 order-1">
                        <div class="box d-flex flex-column justify-content-sm-center justify-content-end h-100">
                            <p class="mb-2">Secure, Advanced, Reliable</p>
                            <h1 class="primary-text">Transform Your Cyber Security Approach</h1>
                            <p>Enhance your digital defense with our cutting-edge cyber security services, ensuring the safety of your data and systems through innovative protection strategies.</p>
                            <div><button class="btn primary-bg text-white py-2 rounded-pill px-4">Contact Us</button></div>
                        </div>
                    </div>

                    <div class="col-1 d-md-flex d-none"></div>

                    <div class="col-sm-6 d-flex align-items-center order-sm-1 order-0 h-100">
                        <img src="../assets/cover5.jpg" alt="" class="mx-md-5 object-fit-contain" />
                    </div>
                </div>
            </div>
            <div class="carousel-item mainPadding h-100" data-bs-interval="2000">
                <div class="row h-100 mt-sm-0 mt-5">
                    <div class="col-md-5 col-sm-6 order-sm-0 order-1">
                        <div class="box d-flex flex-column justify-content-sm-center justify-content-end h-100">
                            <p class="mb-2">Innovative, Insightful, Impactful</p>
                            <h1 class="primary-text">Elevate Your Data Science and Analytics</h1>
                            <p>
                                Unlock the potential of your data with our sophisticated data science and analytics solutions. Experience transformative insights and make data-driven decisions for
                                unparalleled business growth.
                            </p>
                            <div><button class="btn primary-bg text-white py-2 rounded-pill px-4">Contact Us</button></div>
                        </div>
                    </div>

                    <div class="col-1 d-md-flex d-none"></div>

                    <div class="col-sm-6 d-flex align-items-center order-sm-1 order-0 h-100">
                        <img src="../assets/cover6.jpg" alt="" class="mx-md-5 object-fit-contain" />
                    </div>
                </div>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
            <div class="circle primary-bg rounded-circle d-flex align-items-center justify-content-center">
                <i class="fa-solid fa-chevron-left"></i>
            </div>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
            <div class="circle primary-bg rounded-circle d-flex align-items-center justify-content-center">
                <i class="fa-solid fa-chevron-right"></i>
            </div>
            <span class="visually-hidden">Next</span>
        </button>
    </div>

    <div class="about mainPadding py-md-5 py-4">
        <div class="row mx-0 gy-4">
            <div class="col-sm-5 d-flex align-items-center justify-content-center left px-md-5">
                <img src="../assets/aboutUs.png" alt="" class="w-100 square rounded-4" />
            </div>

            <div class="col-sm-7 d-flex flex-column justify-content-center px-sm-3 px-1 right z-1">
                <div class="box px-lg-4">
                    <h3 class="fw-normal primary-text mb-3">About Us</h3>
                    <h5 class="mb-sm-3">Innovative Technology Pioneers</h5>
                    <p class="lh-lg mb-sm-4">
                        Established in 2021 as a start-up company under Kerala Start-up Mission, Festa BPS quickly gained recognition as a start-up by the Department for Promotion of Industry and
                        Internal Trade. We specialize in bespoke technology solutions, blending expertise in mobile and web application development, Windows software, e-commerce, and product design.
                        Our approach mirrors the finest in the industry, focusing on tailor-made, user-centric innovations that empower businesses globally. Festa BPS is not just a company; it's a hub
                        of creativity and technical proficiency, dedicated to transforming ideas into impactful digital realities.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="service mainPadding py-md-5 py-4 bg-white">
        <div class="row gy-4 mx-0">
            <div class="col-lg-2 col-sm-6 pe-xl-5 px-sm-3 px-1">
                <div class="d-flex align-items-center justify-content-start w-100">
                    <h4 class="mb-0 fw-normal primary-text">
                        Our<br />
                        Services
                    </h4>
                </div>
            </div>
            <div class="col-lg-10">
                <div class="row gy-4 boxes">
                    <div class="col-lg-4 col-sm-6 px-sm-3 px-1">
                        <div class="card p-sm-4 p-3 border-0 h-100">
                            <img src="../assets/service1.png" alt="" class="mb-3" />
                            <h5 class="fw-normal">Mobile Application Development</h5>
                            <p class="text-secondary lh-lg">Festa BPS creates engaging mobile apps that bring your ideas to life, ensuring a captivating and effective user experience.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 px-sm-3 px-1">
                        <div class="card p-sm-4 p-3 border-0 h-100">
                            <img src="../assets/service2.png" alt="" class="mb-3" />
                            <h5 class="fw-normal">E-commerce Development</h5>
                            <p class="text-secondary lh-lg">Festa BPS builds dynamic e-commerce platforms, designed to make online shopping a breeze and significantly boost your sales.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 px-sm-3 px-1">
                        <div class="card p-sm-4 p-3 border-0 h-100">
                            <img src="../assets/service3.png" alt="" class="mb-3" />
                            <h5 class="fw-normal">Web Application Development</h5>
                            <p class="text-secondary lh-lg">
                                Festa BPS develops custom web applications, perfectly blending user-friendliness with innovative functionality to elevate your online presence.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end mt-4">
            <RouterLink to="/services"><button class="btn primary-bg text-white py-2 rounded-pill px-4">See All</button></RouterLink>
        </div>
    </div>

    <div class="kmawwd py-md-5 py-4 mainPadding">
        <div class="row mx-0 gy-3">
            <div class="col-sm-7 d-flex flex-column justify-content-center px-1">
                <div class="box text-sm-end px-lg-4">
                    <h5 class="mb-sm-4">Behind the Scenes at Festa BPS</h5>
                    <p class="lh-lg mb-0">
                        Step inside our world with this company profile video from Festa BPS. See first-hand how our dedicated team combines innovative technology with a personal touch to transform
                        businesses. We're excited to share our story and show you how we make a real difference in the digital world.
                    </p>
                </div>
            </div>
            <div class="col-sm-5 px-1">
                <div class="video rounded-4 overflow-hidden h-100 w-100 position-relative">
                    <img src="../assets/kmawwd-1.png" alt="" class="w-100 h-100" />
                </div>
            </div>
        </div>
    </div>

    <feedback />

    <div class="why-choose-us mainPadding py-md-5 py-4 position-relative">
        <!-- <img src="./assets/lowOpacityButterfly.png" alt="" class="position-absolute top-0"> -->
        <div class="row gy-4 mx-0">
            <div class="col-lg-3 col-sm-6 pe-xl-5 px-sm-3 px-1">
                <div class="d-flex align-items-center justify-content-start w-100">
                    <h4 class="mb-0 fw-normal p-3 primary-text">
                        Why<br />
                        Choose Us
                    </h4>
                </div>
            </div>
            <div class="col-lg-9 col-sm-6 px-sm-3 px-1">
                <div class="row gy-4">
                    <div class="col-lg-6">
                        <div class="card p-sm-4 p-3 border-0 h-100">
                            <div class="box mb-3 primary-bg d-flex align-items-center justify-content-center">
                                <i class="fa-solid fa-puzzle-piece fa-2x text-white"></i>
                            </div>
                            <h5 class="fw-normal">Innovative Solutions</h5>
                            <p class="text-secondary lh-lg">
                                At Festa BPS, we're not just developers; we're innovators. We constantly explore cutting-edge technologies to create solutions that not only meet but exceed your
                                business needs.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="card p-sm-4 p-3 border-0 h-100">
                            <div class="box mb-3 primary-bg d-flex align-items-center justify-content-center">
                                <i class="fa-solid fa-dollar-sign fa-2x text-white"></i>
                            </div>
                            <h5 class="fw-normal">Client-Centric Approach</h5>
                            <p class="text-secondary lh-lg">
                                Our philosophy revolves around our clients. We listen, understand, and then create tailor-made solutions that perfectly align with your specific business objectives and
                                challenges.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="card p-sm-4 p-3 border-0 h-100">
                            <div class="box mb-3 primary-bg d-flex align-items-center justify-content-center">
                                <i class="fa-solid fa-list-check fa-2x text-white"></i>
                            </div>
                            <h5 class="fw-normal">Proven Expertise</h5>
                            <p class="text-secondary lh-lg">
                                With a track record of success, our team combines experience, skill, and dedication to deliver exceptional results, ensuring your project is in capable and reliable
                                hands.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="card p-sm-4 p-3 border-0 h-100">
                            <div class="box mb-3 primary-bg d-flex align-items-center justify-content-center">
                                <i class="fa-solid fa-thumbs-up fa-2x text-white"></i>
                            </div>
                            <h5 class="fw-normal">Future-Ready Development</h5>
                            <p class="text-secondary lh-lg">
                                We don’t just build for today; we create for tomorrow. Our forward-thinking approach means your project is designed to adapt and thrive in an ever-evolving digital
                                landscape.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <howWeWork />

    <div class="blogs mainPadding py-md-5 py-4 position-relative">
        <!-- <img src="./assets/lowOpacityButterfly.png" alt="" class="position-absolute top-0"> -->
        <div class="row gy-4 mx-0">
            <div class="col-lg-3 col-sm-6 pe-xl-5 px-sm-3 px-1">
                <div class="d-flex align-items-center justify-content-start w-100">
                    <h4 class="mb-0 fw-normal p-3 primary-text">
                        From<br />
                        Our Blogs
                    </h4>
                </div>
            </div>
            <div class="col-lg-9 col-sm-6 px-sm-3 px-1">
                <div class="gy-4 cards" v-if="blogArray.length">
                    <RouterLink v-for="(blog, index) in blogArray" :key="blog.blogId" :to="{ name: 'blogSingle', params: { id: blog.blogId } }" class="text-decoration-none">
                        <BlogBox v-if="index < 4" :blog="blog" />
                    </RouterLink>
                </div>
                <div v-else class="cards">
                    <div class="card placeholder-glow" aria-hidden="true">
                        <img src="../assets/loadingImagePlaceholder.png" class="card-img-top w-100 placeholder" alt="" />
                        <div class="card-body">
                            <h5 class="card-title placeholder-glow">
                                <span class="placeholder col-6"></span>
                            </h5>
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-7"></span>
                                <span class="placeholder col-4"></span>
                                <span class="placeholder col-4"></span>
                                <span class="placeholder col-6"></span>
                                <span class="placeholder col-8"></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end mt-4" v-if="blogArray.length > 4">
            <RouterLink to="/blogs"><button class="btn primary-bg text-white py-2 rounded-pill px-4">See All</button></RouterLink>
        </div>
    </div>

    <contactUs />
</template>

<script>
import feedback from "../components/feedback.vue";
import howWeWork from "../components/HowWeWork.vue";
import BlogBox from "../components/BlogBox.vue";
import contactUs from "../components/ContactUs.vue";
import store from "../store";
export default {
    name: "HomePage",
    components: {
        feedback,
        howWeWork,
        BlogBox,
        contactUs,
    },
    data() {
        return {
            blogArray: [],
        };
    },
    mounted() {
        document.title = "Festa Home";
        store.dispatch("fetchBlogData").then(() => {
            this.blogArray = store.state.blogDataArray.message;
        });
    },
};
</script>

<style>
.cover img {
    width: 80% !important;
}
.cover .circle {
    width: 30px;
    height: 30px;
}

.cover .carousel-control-next {
    right: 40px;
    width: auto;
    height: 30px;
    top: 90%;
}

.cover .carousel-control-prev {
    right: 85px;
    width: 30px;
    height: 30px;
    top: 90%;
    left: auto;
}
.service .boxes .card img {
    width: 60px;
    aspect-ratio: 1;
    object-fit: contain;
}
.service .card:hover,
.why-choose-us .card:hover {
    background-color: #d9f2fc !important;
}

.why-choose-us .card:hover .box {
    border-radius: 4px 18px;
}

.why-choose-us .card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.why-choose-us .card.animate {
    opacity: 0;
    transform: rotateY(180deg);
    animation: mainAnima 0.5s forwards;
}

.why-choose-us .card .box {
    width: 60px;
    aspect-ratio: 1;
    border-radius: 18px 4px;
    transition: all 0.5s ease;
}
.blogs .cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}
@media (max-width: 991.98px) {
    .blogs .cards {
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
    }
    .cover img {
        width: 100% !important;
    }
}
@media (max-width: 575.98px) {
    .cover {
        height: 100% !important;
    }
}
</style>
