<template>
    <div class="feedback py-md-5 py-4 d-flex flex-lg-row flex-column align-items-center">
        <div class="bg mainPadding py-5 position-relative secondary-bg overflow-hidden">
            <div class="px-xl-4 text-white position-relative z-1">
                <div
                    v-for="(testimonial, index) in testimonialArray"
                    :key="testimonial.slno"
                    class="box box1text my-lg-5 pe-lg-4 d-flex flex-column align-items-center"
                    :class="{ 'd-none': index !== currentItem }"
                    v-show="currentItem == index"
                >
                    <h4 class="fw-normal mb-4">User Feedback</h4>
                    <p class="text-center lh-lg">{{ testimonial.description }}</p>
                    <h5 class="mb-0 py-2 border-bottom border-white border-2 fw-normal">{{ testimonial.name }}</h5>
                </div>
                <!-- <div class="box box1text my-lg-5 pe-lg-4 d-flex flex-column align-items-center d-none" v-show="currentItem === 1">
                    <h4 class="fw-normal mb-4">User Feedback</h4>
                    <p class="text-center lh-lg">
                        Lorem ipsum dolor sit amet consecte ollitia,molestiae quas vel sint commod voluptatum laborumnumquam bland sed odit fugiatLorem ipsum dolor sit icing elit. Maxime
                        mollitia,molestiae commodi repu
                    </p>
                    <h5 class="mb-0 py-2 border-bottom border-white border-2 fw-normal">Steven Joseph</h5>
                </div>
                <div class="box box1text my-lg-5 pe-lg-4 d-flex flex-column align-items-center d-none" v-show="currentItem === 2">
                    <h4 class="fw-normal mb-4">User Feedback</h4>
                    <p class="text-center lh-lg">
                        Lorem ipsum dolor sit amet consecte ollitia,molestiae quas vel sint commod voluptatum laborumnumquam bland sed odit fugiatLorem ipsum dolor sit icing elit. Maxime
                        mollitia,molestiae commodi repu
                    </p>
                    <h5 class="mb-0 py-2 border-bottom border-white border-2 fw-normal">Vishal Kumar</h5>
                </div>
                <div class="box box1text my-lg-5 pe-lg-4 d-flex flex-column align-items-center d-none" v-show="currentItem === 3">
                    <h4 class="fw-normal mb-4">User Feedback</h4>
                    <p class="text-center lh-lg">
                        Lorem ipsum dolor sit amet consecte ollitia,molestiae quas vel sint commod voluptatum laborumnumquam bland sed odit fugiatLorem ipsum dolor sit icing elit. Maxime
                        mollitia,molestiae commodi repu
                    </p>
                    <h5 class="mb-0 py-2 border-bottom border-white border-2 fw-normal">Rohan C Dolphy</h5>
                </div>
                <div class="box box1text my-lg-5 pe-lg-4 d-flex flex-column align-items-center d-none" v-show="currentItem === 4">
                    <h4 class="fw-normal mb-4">User Feedback</h4>
                    <p class="text-center lh-lg">
                        Lorem ipsum dolor sit amet consecte ollitia,molestiae quas vel sint commod voluptatum laborumnumquam bland sed odit fugiatLorem ipsum dolor sit icing elit. Maxime
                        mollitia,molestiae commodi repu
                    </p>
                    <h5 class="mb-0 py-2 border-bottom border-white border-2 fw-normal">Alen Antony</h5>
                </div> -->
                <!-- Repeat for other feedback boxes -->
            </div>
            <img src="../assets/contact-box-bg.png" alt="" class="position-absolute top-50 start-50 translate-middle w-100" />
        </div>
        <div class="right d-flex justify-content-lg-start justify-content-center">
            <div class="container position-relative p-0 mx-0">
                <div class="wrapper position-relative w-100" ref="wrapper">
                    <div v-for="(testimonial, index) in testimonialArray" :key="testimonial.slno" class="item overflow-hidden" :class="{ 'active ': index == currentItem }" @click="boxClick(index)">
                        <img :src="`${this.BASE_URL}${testimonial.image}`" :alt="`${testimonial.alt}`" class="w-100 h-100" @error="handleImageError" />
                    </div>
                    <!-- <div class="item overflow-hidden" @click="boxClick(1)">
                        <img src="../assets/Feedback-2.png" alt="" class="w-100 h-100" />
                    </div>
                    <div class="item overflow-hidden" @click="boxClick(2)">
                        <img src="../assets/Feedback-3.png" alt="" class="w-100 h-100" />
                    </div>
                    <div class="item overflow-hidden" @click="boxClick(3)">
                        <img src="../assets/Feedback-4.png" alt="" class="w-100 h-100" />
                    </div>
                    <div class="item overflow-hidden" @click="boxClick(4)">
                        <img src="../assets/Feedback-5.png" alt="" class="w-100 h-100" />
                    </div> -->
                    <!-- Repeat for other feedback boxes -->
                </div>
                <img src="../assets/mainLogo.png" alt="" class="position-absolute top-50 start-50 translate-middle feedLogo" />
            </div>
        </div>
    </div>
</template>

<script>
import store from "../store";
import imageNotFound from "../assets/image-not-found.jpg";
export default {
    name: "feedbackComponent",
    data() {
        return {
            currentItem: 0, // Set currentItem to 0 to initially display the first feedback box
            mediaQuery575: window.matchMedia("(max-width: 575.98px)"),
            mediaQuery1199: window.matchMedia("(max-width: 1199.98px)"),
            items: null,
            angleStep: null,
            radius: null,
            textItem: null,
            testimonialArray: [],
        };
    },
    mounted() {
        store.dispatch("fetchTestimonialData").then(() => {
            this.testimonialArray = store.state.testimonialDataArray.message;

            // Wait for the next tick to ensure the elements are rendered
            this.$nextTick(() => {
                this.items = Array.from(document.querySelectorAll(".feedback .right .item"));
                console.log(this.items);
                this.angleStep = (2 * Math.PI) / this.items.length;
                this.radius = 200; // Adjust this value as needed
                this.positionItems();

                this.mediaQuery575 = window.matchMedia("(max-width: 575.98px)");
                this.mediaQuery991 = window.matchMedia("(max-width: 991.98px)");

                this.wrapper = this.$refs.wrapper; // Assign wrapper element reference

                this.items.forEach((item, index) => {
                    item.addEventListener("click", () => {
                        this.currentItem = index;
                        this.items.forEach((item) => {
                            item.classList.remove("active");
                        });
                        item.classList.add("active");
                        this.showFeedbackBox(index);
                        this.boxClick(index); // Call boxClick method to rotate the carousel
                    });
                });

                this.textItem = document.querySelectorAll(".feedback .bg .box");
            });
        });
    },

    methods: {
        positionItems() {
            this.items.forEach((item, index) => {
                const angle = this.angleStep * index;
                let x, y;

                if (this.mediaQuery575.matches) {
                    this.radius = 100;
                    x = Math.cos(angle) * this.radius + 70;
                    y = Math.sin(angle) * this.radius + 70;
                } else if (this.mediaQuery1199.matches) {
                    this.radius = 150;
                    x = Math.cos(angle) * this.radius + 110;
                    y = Math.sin(angle) * this.radius + 110;
                } else {
                    this.radius = 200;
                    x = Math.cos(angle) * this.radius + 150;
                    y = Math.sin(angle) * this.radius + 150;
                }

                item.style.left = `${x}px`;
                item.style.top = `${y}px`;
            });
        },
        boxClick(index) {
            this.currentItem = index;
            this.showFeedbackBox(index);
            if (index == 0) {
                if (this.mediaQuery991.matches) {
                    this.wrapper.style.transform = "rotate(270deg)";
                    this.items.forEach((e) => {
                        e.style.transform = "rotate(92deg)";
                    });
                } else {
                    this.wrapper.style.transform = "rotate(180deg)";
                    this.items.forEach((e) => {
                        e.style.transform = "rotate(180deg)";
                    });
                }
            } else if (index == 1) {
                if (this.mediaQuery991.matches) {
                    this.wrapper.style.transform = "rotate(198deg)";
                    this.items.forEach((e) => {
                        e.style.transform = "rotate(166deg)";
                    });
                } else {
                    this.wrapper.style.transform = "rotate(108deg)";
                    this.items.forEach((e) => {
                        e.style.transform = "rotate(253deg)";
                    });
                }
            } else if (index == 2) {
                if (this.mediaQuery991.matches) {
                    this.wrapper.style.transform = "rotate(127deg)";
                    this.items.forEach((e) => {
                        e.style.transform = "rotate(230deg)";
                    });
                } else {
                    this.wrapper.style.transform = "rotate(36deg)";
                    this.items.forEach((e) => {
                        e.style.transform = "rotate(324deg)";
                    });
                }
            } else if (index == 3) {
                if (this.mediaQuery991.matches) {
                    this.wrapper.style.transform = "rotate(54deg)";
                    this.items.forEach((e) => {
                        e.style.transform = "rotate(312deg)";
                    });
                } else {
                    this.wrapper.style.transform = "rotate(324deg)";
                    this.items.forEach((e) => {
                        e.style.transform = "rotate(36deg)";
                    });
                }
            } else if (index == 4) {
                if (this.mediaQuery991.matches) {
                    this.wrapper.style.transform = "rotate(342deg)";
                    this.items.forEach((e) => {
                        e.style.transform = "rotate(18deg)";
                    });
                } else {
                    this.wrapper.style.transform = "rotate(252deg)";
                    this.items.forEach((e) => {
                        e.style.transform = "rotate(108deg)";
                    });
                }
            }
        },

        showFeedbackBox(index) {
            this.textItem.forEach((item, idx) => {
                console.log(item);
                if (idx === index) {
                    item.classList.remove("d-none");
                } else {
                    item.classList.add("d-none");
                }
            });
        },

        handleImageError(event) {
            event.target.src = imageNotFound;
        },
    },
};
</script>

<style>
.feedback .bg {
    border-radius: 10px 102px;
    width: 60%;
}

.feedback .bg.animate {
    opacity: 0;
    transform: translateX(-100%);
    animation: mainAnima 0.5s forwards;
}

.feedback .right {
    border-radius: 10px 102px;
    width: 40%;
}

.feedback .right .container {
    width: 400px;
}

.feedback .right .wrapper {
    transform: rotate(180deg);
    aspect-ratio: 1;
    transition: transform 1s ease;
    /* Adjust the duration as needed */
}

.feedback .right .container .feedLogo {
    width: 100px;
    transform: translate(-50%, -50%) rotate(0deg) !important;
    transition: all 1s ease;
}

.feedback .right .item {
    width: 100px;
    aspect-ratio: 1;
    border-radius: 100%;
    z-index: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transform: rotate(180deg);
    transform-origin: 50% 50%;
    border: 10px solid #ffffff00;
    transition: all 1s ease;
    opacity: 0.7;
}

.feedback .right .item.active {
    opacity: 1;
    border: 10px solid #ffffff;
}
@media (max-width: 1199.98px) {
    .feedback .right .container {
        width: 300px;
    }

    .feedback .right .item {
        border: 6px solid #ffffff00;
        width: 80px;
    }

    .feedback .right .item.active {
        border: 6px solid #ffffff;
    }
}
@media (max-width: 991.98px) {
    .feedback .right .wrapper {
        transform: rotate(270deg);
    }

    .feedback .right .item {
        transform: rotate(92deg);
    }

    .feedback .bg {
        width: 100%;
    }

    .feedback .right {
        width: 100%;
    }
    @media (max-width: 575.98px) {
    }
    .feedback .right .container {
        width: 200px;
    }

    .feedback .right .item {
        border: 4px solid #ffffff00;
        width: 60px;
    }

    .feedback .right .item.active {
        border: 4px solid #ffffff;
    }

    .feedback .right .container .feedLogo {
        width: 50px;
    }
}
</style>
