<template>
    <div class="secondCover w-100 mainPadding position-relative overflow-hidden py-5 bg-black d-flex align-items-center justify-content-center bg-black bg-opacity-50">
        <img src="../assets/mainLogoLowOpacity.png" alt="" class="position-absolute top-50 start-50 translate-middle z-2" />
        <div class="box text-white text-center mt-5">
            <h1>Work With Us</h1>
            <p class="mb-0">
                Join Our Team at Festa BPS! Explore a world of opportunities where innovation, growth, and collaboration come together to shape your career and the future of technology.
            </p>
        </div>
    </div>

    <div class="about mainPadding py-md-5 py-4">
        <div class="row mx-0 gy-4">
            <div class="col-sm-5 d-flex align-items-center justify-content-center left px-md-5">
                <img src="../assets/aboutUs.png" alt="" class="w-100 square rounded-4" />
            </div>

            <div class="col-sm-7 d-flex flex-column justify-content-center px-sm-3 px-1 right z-1">
                <div class="box px-lg-4">
                    <h3 class="fw-normal primary-text mb-3">Careers</h3>
                    <h5 class="mb-sm-3">Ready to Elevate Your Career?</h5>
                    <p class="lh-lg mb-sm-4">
                        Joining Festa BPS means more than just a job. It's where your passion for technology and creativity can truly shine. Here, every team member is important, and your ideas help
                        shape our success. We work together, learn from each other, and build tech that really helps our clients. At Festa BPS, your work makes a difference, and your growth is part of
                        our journey. If you're ready for a place where your skills are valued and your career can soar, Festa BPS is waiting for you.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="service mainPadding py-md-5 py-4">
        <div class="row gy-4 mx-0">
            <div class="col-12 pe-xl-5 px-sm-3 px-1">
                <div class="d-flex align-items-center justify-content-start w-100">
                    <h4 class="mb-0 fw-normal">About Working Here</h4>
                </div>
            </div>
            <div class="col-12">
                <div class="row gy-4 boxes">
                    <div class="col-lg-3 col-sm-6 px-sm-3 px-1">
                        <div class="card p-sm-4 p-3 border-0 h-100 bg-transparent">
                            <img src="../assets/service1.png" alt="" class="mb-3" />
                            <h5 class="fw-normal">Design</h5>
                            <p class="text-secondary lh-lg">Lorem ipsum dolorees e mollitia,molestiaee quuntur voluptasrem e mollitia,mole.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 px-sm-3 px-1">
                        <div class="card p-sm-4 p-3 border-0 h-100 bg-transparent">
                            <img src="../assets/service2.png" alt="" class="mb-3" />
                            <h5 class="fw-normal">Development</h5>
                            <p class="text-secondary lh-lg">Lorem ipsum dolorees e mollitia,molestiaee quuntur voluptasrem e mollitia,mole.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 px-sm-3 px-1">
                        <div class="card p-sm-4 p-3 border-0 h-100 bg-transparent">
                            <img src="../assets/service3.png" alt="" class="mb-3" />
                            <h5 class="fw-normal">Research</h5>
                            <p class="text-secondary lh-lg">Lorem ipsum dolorees e mollitia,molestiaee quuntur voluptasrem e mollitia,mole.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mvv mainPadding py-md-5 py-4 bg-white">
        <h4 class="mb-0 fw-normal mb-4">Open Positions</h4>
        <div class="mvvNav">
            <RouterLink to="/careers/developer" class="py-2 text-decoration-none text-body" :class="{ active: $route.path === '/careers/developer' || $route.path === '/careers' }"
                >Developer</RouterLink
            >
            <RouterLink to="/careers/designer" class="mx-3 py-2 text-decoration-none text-body" :class="{ active: $route.path === '/careers/designer' }">Designer</RouterLink>
            <RouterLink to="/careers/researcher" class="py-2 text-decoration-none text-body" :class="{ active: $route.path === '/careers/researcher' }">Researcher</RouterLink>
        </div>

        <RouterView></RouterView>
    </div>

    <contactUs />
</template>

<script>
import contactUs from "../components/ContactUs.vue";
export default {
    name: "CareersPage",
    components: {
        contactUs,
    },
    mounted() {
        document.title = "Festa Careers";
    },
};
</script>

<style></style>
