<template>
    <div class="secondCover w-100 mainPadding position-relative overflow-hidden py-5 bg-black d-flex align-items-center justify-content-center bg-black bg-opacity-50">
        <img src="../assets/mainLogoLowOpacity.png" alt="" class="position-absolute top-50 start-50 translate-middle z-2" />
        <div class="box text-white text-center mt-5">
            <h1>Blogs</h1>
            <p class="mb-0">Explore our Festa BPS blog for expert insights and practical tech tips. Stay updated and ahead in the digital world with our latest posts.</p>
        </div>
    </div>

    <div class="blog z-2 position-relative">
        <div class="rightBlog mainPadding pb-md-5 pb-4 mt-5">
            <div class="gy-4 cards" v-if="blogArray.length">
                <RouterLink v-for="blog in blogArray" :key="blog.blogId" :to="{ name: 'blogSingle', params: { id: blog.blogId } }" class="text-decoration-none">
                    <BlogBox :blog="blog" />
                </RouterLink>
            </div>
            <div v-else class="cards">
                <div class="card placeholder-glow" aria-hidden="true">
                    <img src="../assets/loadingImagePlaceholder.png" class="card-img-top w-100 placeholder" alt="" />
                    <div class="card-body">
                        <h5 class="card-title placeholder-glow">
                            <span class="placeholder col-6"></span>
                        </h5>
                        <p class="card-text placeholder-glow">
                            <span class="placeholder col-7"></span>
                            <span class="placeholder col-4"></span>
                            <span class="placeholder col-4"></span>
                            <span class="placeholder col-6"></span>
                            <span class="placeholder col-8"></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <contactUs />
</template>

<script>
import BlogBox from "../components/BlogBox.vue";
import contactUs from "../components/ContactUs.vue";
import store from "../store";
export default {
    name: "BlogPage",
    components: {
        BlogBox,
        contactUs,
    },
    data() {
        return {
            blogArray: [],
        };
    },
    methods: {
        navigateTo(path) {
            // Use Vue Router to navigate to the specified path
            this.$router.push(path);
        },
    },
    mounted() {
        document.title = "Festa Blogs";
        store.dispatch("fetchBlogData").then(() => {
            this.blogArray = store.state.blogDataArray.message;
        });
    },
};
</script>

<style>
.rightBlog .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}
@media (max-width: 575.98px) {
    .rightBlog .cards {
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
    }
}
</style>
