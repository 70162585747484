<template>
    <div class="contact-box mainPadding py-md-5 py-4">
        <div class="box secondary-bg text-center py-5 px-sm-5 px-3 text-white rounded-4 d-flex align-items-center justify-content-center position-relative">
            <div class="half-width position-relative z-1">
                <h4>We'd love to hear from you</h4>
                <p class="my-4">Transform your ideas with us at Festa BPS. Reach out now and let's start making your business goals a successful reality together.</p>
                <RouterLink to="/contact" class="d-flex justify-content-center mt-4 text-decoration-none"
                    ><button class="btn bg-white primary-text py-2 rounded-pill px-4">Contact Us</button></RouterLink
                >
            </div>
            <img src="../assets/contact-box-bg.png" alt="" class="position-absolute top-0 start-0 w-100 h-100" />
        </div>
    </div>
</template>

<script>
export default {
    name: "ContactUs",
};
</script>

<style>
.contact-box .half-width {
    width: 50%;
}
@media (max-width: 991.98px) {
    .festa .contact-box .half-width {
        width: 100%;
    }
}
</style>
