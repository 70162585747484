<template>
    <div class="secondCover w-100 mainPadding position-relative overflow-hidden py-5 bg-black d-flex align-items-center justify-content-center bg-black bg-opacity-50">
        <img src="../assets/mainLogoLowOpacity.png" alt="" class="position-absolute top-50 start-50 translate-middle z-2" />
        <div class="box text-white text-center mt-5">
            <h1>About Us</h1>
            <p class="mb-0">With Festa BPS, experience a partnership that values your vision, offering tailor-made digital solutions to elevate your business.</p>
        </div>
    </div>

    <div class="about mainPadding py-md-5 py-4">
        <div class="row mx-0 gy-4">
            <div class="col-sm-5 d-flex align-items-center justify-content-center left px-md-5">
                <img src="../assets/aboutUs.png" alt="" class="w-100 square rounded-4" />
            </div>

            <div class="col-sm-7 d-flex flex-column justify-content-center px-sm-3 px-1 right z-1">
                <div class="box px-lg-4">
                    <h3 class="fw-normal primary-text mb-3">About Us</h3>
                    <h5 class="mb-sm-3">Innovative Technology Pioneers</h5>
                    <p class="lh-lg mb-sm-4">
                        Established in 2021 as a start-up company under Kerala Start-up Mission, Festa BPS quickly gained recognition as a start-up by the Department for Promotion of Industry and
                        Internal Trade. We specialize in bespoke technology solutions, blending expertise in mobile and web application development, Windows software, e-commerce, and product design.
                        Our approach mirrors the finest in the industry, focusing on tailor-made, user-centric innovations that empower businesses globally. Festa BPS is not just a company; it's a hub
                        of creativity and technical proficiency, dedicated to transforming ideas into impactful digital realities.
                    </p>
                </div>
            </div>

            <div class="col-12">
                <h5 class="mb-sm-3 mt-5">Our Technology & Security Edge</h5>
                <p class="lh-lg">
                    Experience the technological edge with Festa BPS, where advanced tech meets top-tier security. Our expert team integrates cutting-edge solutions with strong data protection,
                    ensuring your project's success and security. Our approach, which emphasizes market insights and ROI, is delivered through transparent and client-centric development practices.
                    This unique blend of technology, security, and client-focused strategy is what makes us a standout partner for your digital needs.
                </p>
            </div>
        </div>
    </div>

    <div class="mvv mainPadding py-md-5 py-4">
        <div class="mvvNav">
            <RouterLink class="py-2 cursorPointer text-decoration-none text-body" :class="{ active: $route.path === '/about/ourMission' || $route.path === '/about' }" to="/about/ourMission"
                >Our Mission</RouterLink
            >
            <RouterLink class="mx-3 py-2 cursorPointer text-decoration-none text-body" :class="{ active: $route.path === '/about/ourVision' }" to="/about/ourVision">Vision</RouterLink>
            <RouterLink class="py-2 cursorPointer text-decoration-none text-body" :class="{ active: $route.path === '/about/ourValue' }" to="/about/ourValue">Value</RouterLink>
        </div>

        <RouterView></RouterView>
    </div>

    <feedback />

    <div class="team mainPadding py-md-5 py-4 mb-md-0 mb-4">
        <div class="row gy-4 mx-0">
            <div class="col-lg-3 col-sm-6 pe-xl-5 px-sm-3 px-1">
                <div class="primary-text d-flex align-items-center justify-content-start w-100">
                    <h4 class="mb-0 fw-normal primary-text">
                        Meet<br />
                        Our Team
                    </h4>
                </div>
            </div>
            <div class="col-lg-9 px-sm-3 px-1 d-flex align-items-center justify-content-between right position-relative">
                <!-- <div class="slider position-relative"> -->
                <swiper
                    class="swiper mySwiper teamSwiper h-100"
                    :modules="modules"
                    :slides-per-view="calculateSlidesPerView()"
                    :space-between="calculateSpaceBetween()"
                    :navigation="{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }"
                    @swiper="onSwiper"
                    @slideChange="onSlideChange"
                    :autoplay="{ delay: 5000 }"
                >
                    <!-- <div class="swiper-wrapper h-100"> -->
                    <swiper-slide v-for="team in teamArray" :key="team.slno" class="text-decoration-none box overflow-hidden rounded-3 position-relative" @click="goToTeamSinglePage(team.slno)">
                        <teamBox :team="team" />
                    </swiper-slide>
                    <!-- </div> -->
                </swiper>
                <div class="swiper-button-next start-0 ms-5 text-secondary border border-1 border-secondary rounded-circle"></div>
                <div class="swiper-button-prev me-5 text-secondary border border-1 border-secondary rounded-circle"></div>
                <!-- </div> -->
            </div>
        </div>
    </div>

    <contactUs />
</template>

<script>
import feedback from "../components/feedback.vue";
import contactUs from "../components/ContactUs.vue";
import teamBox from "../components/TeamBox.vue";
import store from "../store";
import { Navigation, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";

export default {
    name: "AboutUs",
    components: {
        feedback,
        contactUs,
        teamBox,
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            boxes: null,
            currentIndex: 0,
            intervalId: null,
            swiper: null,
            teamArray: [],
        };
    },
    methods: {
        goToTeamSinglePage(teamId) {
            // Construct the route path manually
            const routePath = `/teamSingle/${teamId}`; // Adjust the route path as per your route configuration

            // Navigate programmatically
            this.$router.push(routePath);
        },
        calculateSlidesPerView() {
            // Adjust the number of slides per view based on screen width
            if (window.innerWidth >= 576.98) {
                return 3; // Number of slides per view for large screens
            } else {
                return 1; // Number of slides per view for small screens
            }
        },
        calculateSpaceBetween() {
            // Adjust the number of slides per view based on screen width
            if (window.innerWidth >= 576.98) {
                return 20; // Number of slides per view for large screens
            } else {
                return 10; // Number of slides per view for small screens
            }
        },
    },
    mounted() {
        document.title = "Festa About Us";
        store.dispatch("fetchTeamData").then(() => {
            this.teamArray = store.state.teamDataArray.message;
        });
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {};
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, A11y, Autoplay],
        };
    },
};
</script>

<style>
.mvv .mvvNav a.active {
    color: var(--primary-color) !important;
    border-bottom: 2px solid var(--primary-color);
}

.team .right {
    height: 270px;
}

.team .box {
    width: 140px;
    aspect-ratio: 1;
    transition: all 1s ease-in-out;
}

.team .box .layer {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.567) 100%);
}

.team img {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.team .icon {
    aspect-ratio: 1;
    width: 30px;
    font-size: 0.7rem;
}
.team .swiper-button-next,
.team .swiper-button-prev {
    top: 115%;
    width: 30px;
    height: 30px;
}

.team .swiper-button-next::after,
.team .swiper-button-prev::after {
    font-size: 0.8rem;
}
</style>
