<template>
    <div class="footer mainPadding bg-dark text-white pt-5 mt-md-5 mt-3" id="footer">
        <div class="row gy-4 z-2 position-relative">
            <div class="col-sm-6">
                <h5 class="fw-normal mb-4">Contact Us</h5>
                <p>
                    No.21/152 Corrazone Coworking Space Panadans,<br />
                    near CORRAZONE, University Road,<br />
                    Kalamassery, Kerala 682022
                </p>
                <p>094960 36834</p>
                <a href="mailto:info@festahub.com" class="text-white text-decoration-none" target="_blank">info@festahub.com</a>
            </div>
            <div class="col-sm-3 d-flex flex-column">
                <h5 class="fw-normal mb-4">Quick Links</h5>
                <RouterLink to="/" class="text-white mb-2 text-decoration-none">Home</RouterLink>
                <RouterLink to="/about" class="text-white mb-2 text-decoration-none">About Us</RouterLink>
                <RouterLink to="/services" class="text-white mb-2 text-decoration-none">Services</RouterLink>
                <RouterLink to="/contact" class="text-white mb-2 text-decoration-none">Contact Us</RouterLink>
                <RouterLink to="/careers" class="text-white text-decoration-none">Careers</RouterLink>
            </div>
            <div class="col-sm-3 d-flex flex-column justify-content-between">
                <img src="../assets/logo.png" alt="" class="mb-3" />
                <div class="box">
                    <h5 class="fw-normal mb-4">Social</h5>
                    <div class="mb-3">
                        <a href="https://www.facebook.com/festabps/?paipv=0&eav=AfZXVwQO_LdolsNNXqePYwKYReSQsEsTd7mZNUzoa9oGrQQQynbomNMM8-3my2aMIvg&_rdr" target="_blank" rel="noopener noreferrer"
                            ><i class="fa-brands fa-facebook-f text-white"></i
                        ></a>
                        <a href="https://twitter.com/festabps" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-twitter mx-3 text-white"></i></a>
                        <a href="https://www.linkedin.com/company/festahub/mycompany/" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-linkedin-in text-white"></i></a>
                    </div>
                    <div>
                        <a href="https://www.instagram.com/festabps/" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-instagram me-3 text-white"></i></a>
                        <a href="https://www.youtube.com/channel/UCGU3eOdyJMn_HmI9EgD8G9w" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-youtube text-white"></i></a>
                    </div>
                </div>
            </div>
            <p class="text-center mt-5">© 2024 festa All Rights Reserved.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "FooterComponent",
};
</script>

<style>
.footer img {
    width: 180px;
}
</style>
