<template>
    <!-- <a class=""> -->
    <div class="layer position-absolute w-100 h-100 top-0 text-white">
        <div class="text p-3 w-100 h-100 d-flex flex-column justify-content-end">
            <p class="mb-0">{{ team.name }}</p>
            <p class="mb-0 fw-light">{{ team.designation }}</p>
        </div>
    </div>
    <img :src="`${this.BASE_URL}${team.image}`" alt="" class="w-100 h-100" @error="handleImageError" />
    <!-- </a> -->
</template>

<script>
import imageNotFound from "../assets/image-not-found.jpg";
export default {
    name: "TeamBox",
    props: {
        team: Object,
    },
    methods: {
        handleImageError(event) {
            event.target.src = imageNotFound;
        },
    },
};
</script>

<style></style>
